// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
@import '../../node_modules/npm-font-source-sans-pro/source-sans-pro';

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.DashMenuMaster {
    height: 500px;
}

.dashboard {
    height: 100% !important;
    width: 100%;
}

.modified-item:hover {
    border-bottom: 2px solid transparent !important;
    color: inherit !important;
}

.modified-item:focus {
    border-bottom: 2px solid transparent !important;
    color: inherit !important;
}

.hero {
    margin-top: 20%;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 400%;
    font-family: 'Source Sans Pro';
    src: url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.masterLogo {
    font-size: 180%;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    color: black;
    font-family: 'Source Sans Pro';
    src: url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
    overflow: hidden;
    white-space: nowrap;
}

.ant-layout-sider-trigger {
    // background-color: white !important;
    color: 'black' !important;
}

.greenButton {
    background-color: #34eb98 !important;
    color: white !important;
    border: 1px solid #34eb98 !important;
}

.greenButton:hover {
    color: white !important;
    border: 1px solid white !important;
}

.purpleButton {
    background-color: #b77ae6 !important;
    color: white !important;
    border: 1px solid #b77ae6 !important;
}

.purpleButton:hover {
    color: white !important;
    border: 1px solid white !important;
}

.redButton {
    //#e67a7a
    background-color: #1890ff !important;
    color: white !important;
    border: 1px solid #1890ff !important;
}

.redButton:hover {
    color: white !important;
    border: 1px solid white !important;
}

.menu-lol {
    width: 256;
    height: 100%;
}

.ant-statistic {
    margin-right: 15px;
    margin-left: 15px;
}

.ant-notification {
    top: 20% !important;
    left: 50%;
    transform: translate(-50%, -20%);
    right: unset !important;
    bottom: unset !important;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
    background: #f7f7f7;
    border-radius: 2px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
}

.padding-override {
    margin-left: 0px !important;
}

#selectAssets {
    width: 100%;
    border-radius: 6px;
}

#selectAssets:hover {
    background-color: #dddddd;
    // background-color: black";
    border-radius: 4px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
}

#triggerSearch {
    border: none;
    outline: none;
    margin: 0px;
    padding: 0px;
    height: 20px;
    width: 100%;
    resize: none;
    white-space: pre;
    background-color: var(--ds-surface, #FFFFFF);
    color: var(--ds-text, #091E42);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    line-height: 20px;
}

.selectedTriggerRow {
    cursor: pointer;
    padding: 6px;
    border-radius: 6px;
    border-bottom: 1px solid #EBECF0;
    border-right: 1px solid #EBECF0;
    border-left: 1px solid #EBECF0;
}

.selectedTriggerRow:hover {
    background-color: #f3f3f3;
    transition: background 0.3s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
}


.triggerRowDelete {
    text-align: center;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    padding: 0px 2px 2px 2px;

}

.triggerRowDelete:hover {
    transition: background 0.3s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    background-color: #F4F5F7;
}

.editableParam {
    background-color: #ffffff00;
    width: auto;
    border: none !important;
    outline: none
}

.editableParam:focus {
    outline: none;
    border: none !important;
}

.triggeraddhover {
    border-radius: 6px;
}

.triggeraddhover:hover {
    background-color: #F4F5F7
}
@primary-color: #1890FF;