@import '../../node_modules/npm-font-source-sans-pro/source-sans-pro';

body {
    margin: 0;
    padding: 10px;
    font-family: 'Source Sans Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-family: 'Source Sans Pro';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label {
    display: block;
    margin-bottom: 10px;
}

input {
    margin-left: 10px;
}

.logo {
    height: 50px;
    width: auto;
}

.logo-items {
    display: inline;
    font-family: 'Barlow';
}

.container {
    width: 75%;
    margin: auto;
    padding: 20px;
}
.center {
    width: 10%;
    margin: auto;
}
.selectors {
    margin-left: 10px;
    margin-right: 10px;
    display: inline block;
    font-weight: bold;
}
.center {
    width: auto;
    margin-bottom: 30px;
}

.dollar {
    margin-bottom: 50px !important;
}

.sup-non {
    font-size: 0.6em;
    color: #4d4d4d !important;
    vertical-align: center;
    font-size: 30px;
}

ul {
    text-align: left;
}

#root{
    height: 100%;
    display: flex;
    align-items: center;
}

#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.site-layout .site-layout-background {
    background: #fff;
}

/* .home-div {
  background: rgb(2,0,36);
} */

a {
    color: white;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
    background: #f7f7f7;
    border-radius: 2px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
}

@primary-color: #1890FF;